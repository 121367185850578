import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { TestCloudFunction } from "../../components/TestCloudFunctions";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1 {...{
      "id": "accepting-arguments"
    }}>{`Accepting arguments`}</h1>
    <p>{`You can do lots with the hello world functions you built in the last exercise. Accepting arguments and data will let you do even more.`}</p>
    <p>{`That's when cloud functions become as powerful as your other JavaScript.`}</p>
    <p>{`We'll explore 3 ways to accept parameters:`}</p>
    <ol>
      <li parentName="ol">{`As part of the URL path`}</li>
      <li parentName="ol">{`As a query string`}</li>
      <li parentName="ol">{`As a data payload`}</li>
    </ol>
    <h2 {...{
      "id": "vercel"
    }}>{`Vercel`}</h2>
    <p>{`Vercel's filename-based routing gives you the simplest approach to path params that I've ever seen 👉 name your file as `}<inlineCode parentName="p">{`/api/[param1]/[param2].js`}</inlineCode>{` and anything in square brackets becomes a named value.`}</p>
    <p>{`You get URL queries and data payloads in the `}<inlineCode parentName="p">{`req`}</inlineCode>{` object.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Create a new file in your hello world project, call it `}<inlineCode parentName="p">{`[name].js`}</inlineCode>{` or `}<inlineCode parentName="p">{`[name].ts`}</inlineCode>{`. Return a `}<inlineCode parentName="p">{`Hello <name>`}</inlineCode>{` string.`}</p>
    <p>{`Make your function return `}<inlineCode parentName="p">{`Hello <name>, beautiful day today <emoji>`}</inlineCode>{` where the emoji comes from query or body params. Default to query, use body if emoji is undefined.`}</p>
    <p>{`Use `}<inlineCode parentName="p">{`req.query`}</inlineCode>{` for path and query params. Use `}<inlineCode parentName="p">{`req.body`}</inlineCode>{` for body payload params.`}</p>
    <p>{`Deploy with `}<inlineCode parentName="p">{`vercel`}</inlineCode>{`, use default answers to prompts.`}</p>
    <TestCloudFunction serviceName="vercel" urlPlaceholder="<domain>/api/<name>?emoji=" jsonPlaceholder={`{"emoji": "..."}`} mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <h2 {...{
      "id": "netlify"
    }}>{`Netlify`}</h2>
    <p>{`Best I can tell, Netlify doesn't support path params. ☹️`}</p>
    <p>{`Query and body params work though and they're all you need. The URLs aren't that pretty anyway.`}</p>
    <h3 {...{
      "id": "exercise-1"
    }}>{`Exercise`}</h3>
    <p>{`Add a `}<inlineCode parentName="p">{`happy-day.js`}</inlineCode>{` file to your hello world project.`}</p>
    <p>{`Make your function return `}<inlineCode parentName="p">{`Hello <name>, beautiful day today <emoji>`}</inlineCode>{` where the emoji comes from query or body params. Default to query, use body if emoji is undefined.`}</p>
    <p>{`Use `}<inlineCode parentName="p">{`event.queryStringParameters`}</inlineCode>{` for query params and `}<inlineCode parentName="p">{`event.body`}</inlineCode>{` for the body payload. You'll need to `}<inlineCode parentName="p">{`JSON.parse`}</inlineCode>{` that one.`}</p>
    <p>{`Deploy with `}<inlineCode parentName="p">{`netlify deploy`}</inlineCode>{`, use defaults to answer any prompts.`}</p>
    <p>{`Your function is live at `}<inlineCode parentName="p">{`<domain>/.netlify/functions/<filename>`}</inlineCode></p>
    <p>{`Try your function with these terminal commands:`}</p>
    <TestCloudFunction serviceName="netlify" urlPlaceholder="<domain>/.netlify/functions/happy-day?name=swizec&emoji=😊" jsonPlaceholder={`{"name": "swizec", "emoji": "😊"}`} mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution-1"
    }}>{`Solution`}</h3>
    <h2 {...{
      "id": "aws-lambda"
    }}>{`AWS Lambda`}</h2>
    <p>{`AWS Lambda lets you accept all 3 types of params.`}</p>
    <h3 {...{
      "id": "exercise-2"
    }}>{`Exercise`}</h3>
    <p>{`Move into `}<inlineCode parentName="p">{`exercise-2`}</inlineCode>{` from the `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises"
      }}>{`serverless-workshop-exercises GitHub repository`}</a>{`.`}</p>
    <p>{`Create a `}<inlineCode parentName="p">{`src/happy-day.ts`}</inlineCode>{` file. Use the same code as your Netlify solution from the previous exercise. Declare the event arg as `}<inlineCode parentName="p">{`APIGatewayEvent`}</inlineCode>{` and change to a TypeScript export`}</p>
    <pre><code parentName="pre" {...{}}>{`exports.handler = async (event) => {
// becomes
export const handler = async (event: APIGatewayEvent) => {
`}</code></pre>
    <p>{`TypeScript will force you to think about undefined params as well. We ignored that with Netlify.`}</p>
    <p>{`Add your new method to `}<inlineCode parentName="p">{`serverless.yml`}</inlineCode>{` and configure:`}</p>
    <ol>
      <li parentName="ol">{`the handler path`}</li>
      <li parentName="ol">{`a GET event`}</li>
      <li parentName="ol">{`a POST event`}</li>
      <li parentName="ol">{`a parametrized path with `}<inlineCode parentName="li">{`{name}`}</inlineCode></li>
    </ol>
    <p>{`Try reading the name path param from `}<inlineCode parentName="p">{`event.pathParameters`}</inlineCode></p>
    <p>{`Deploy with `}<inlineCode parentName="p">{`yarn deploy`}</inlineCode>{` or `}<inlineCode parentName="p">{`npm run deploy`}</inlineCode></p>
    <TestCloudFunction serviceName="netlify" urlPlaceholder="<domain>/swizec?emoji=😊" jsonPlaceholder={`{"emoji": "😊"}`} mdxType="TestCloudFunction" />
    <h3 {...{
      "id": "solution-2"
    }}>{`Solution`}</h3>
    <p><a parentName="p" {...{
        "href": "https://github.com/Swizec/serverless-workshop-exercises/tree/exercise-2-solution"
      }}>{`https://github.com/Swizec/serverless-workshop-exercises/tree/exercise-2-solution`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      